import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFound() {
  return (
    <Box component={Paper} sx={{
      maxWidth: 1920,
      px:4,
      pt:2,
      pb:8,
      width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
      m:"auto"
    }}>
      <Box style={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant='h1'>404 - Page Not Found</Typography>
        <br />
        <Typography variant='subtitle2'>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</Typography>
        <Typography variant='subtitle2'>Please return to the <Link to="/">home page</Link> and try again.</Typography>
      </Box>
    </Box>
  );
}

export default PageNotFound;
