import React, { useState } from "react";
import CustomModal from "../../components/CustomModal";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Copy from "@mui/icons-material/ContentCopy";
import Copied from "@mui/icons-material/AssignmentTurnedIn";
import rehypeRaw from "rehype-raw";

import Markdown from "react-markdown";
import { axios_post_call } from "../../components/AxiosCall";

function RenderFormSubmissionsResponse({
  form_submissions_response,
  set_form_submissions_response,
  set_alert,
  set_empty_form,
  empty_form,
}) {
  const [copied, set_copied] = useState(0);
  const [sent_a_copy_to_email, set_sent_a_copy_to_email] = useState(false);
  const [loading, set_loading] = useState(false);

  function copy_text(text, index) {
    let raw_text = text.replace(/[*_~`]/g, ""); // Remove specific characters
    raw_text = raw_text.replace(
      /<span style="background-color: yellow;">|<\/span>/g,
      ""
    ); // Remove <span> tags with inline style for yellow background

    navigator.clipboard
      .writeText(raw_text)
      .then(() => {
        set_copied(index);
        console.log("Text copied to clipboard:", raw_text);
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
      });
  }

  const send_a_copy_of_the_form_to_email = async () => {
    let url = "/saas/send-a-copy-of-the-form-to-email/";
    let res = await axios_post_call(
      url,
      { form_id: form_submissions_response.form_id },
      set_loading,
      set_alert
    );

    if (res.data.status == 1) {
      set_sent_a_copy_to_email(true);
      set_loading(false);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  return (
    <CustomModal set_preview={set_form_submissions_response}>
      <Box
        sx={{
          textAlign: "left",
          cursor: "auto",
          display: "flex",
          maxWidth: 1920,
          width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
            component={Paper}
            sx={{
              bgcolor: "white",
              position: "relative",
              boxShadow: 12,
              p: 4,
              minWidth: "280px",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Button
                  sx={{ mr: 2 }}
                  onClick={() => {
                    set_form_submissions_response(false);
                  }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    set_form_submissions_response(false);
                    window.scrollTo(0, 0);
                    set_empty_form(empty_form + 1);
                    set_alert(false);
                  }}
                  variant="contained"
                >
                  Create another form
                </Button>
              </Box>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <Button
                disabled={sent_a_copy_to_email}
                  variant="outlined"
                  onClick={() => {
                    if (!sent_a_copy_to_email){
                    send_a_copy_of_the_form_to_email();
                  }
                  }}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : sent_a_copy_to_email ? (
                    "Sent ✅"
                  ) : (
                    "Send a copy of this submission to my email"
                  )}
                </Button>
              </Box>
            </Box>
        </Box>
        {/* [START] Use Case Description */}
        <Box
          component={Paper}
          sx={{
            bgcolor: "white",
            position: "relative",
            boxShadow: 12,
            p: 4,
            minWidth: "280px",
            width: "100%",
            mb: 2,
          }}
        >
          <Box
            onClick={() => {
              copy_text(
                form_submissions_response.form_data.use_case_description,
                1
              );
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 21,
              width: 34,
              height: 34,
              position: "absolute",
              top: 10,
              right: 15,
              cursor: "pointer",
              "&:hover": { bgcolor: "#EAEAEA" },
            }}
          >
            {copied == 1 ? (
              <Copied sx={{ fontSize: 22 }} />
            ) : (
              <Copy sx={{ fontSize: 22 }} />
            )}
          </Box>
          <Box>
            <Typography align="left" variant="h1">
              Use Case Description
            </Typography>
            <Markdown rehypePlugins={[rehypeRaw]}>
              {form_submissions_response.form_data.use_case_description}
            </Markdown>
          </Box>
        </Box>
        {/* [END] Use Case Description */}

        {/* [START] Use Case examples */}
        <Box
          sx={{
            cursor: "auto",
            display: "flex",
            maxWidth: 1920,
            width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={Paper}
            sx={{
              bgcolor: "white",
              position: "relative",
              boxShadow: 12,
              p: 4,
              minWidth: "280px",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              onClick={() => {
                copy_text(
                  form_submissions_response.form_data.use_case_sample_messages,
                  2
                );
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 21,
                width: 34,
                height: 34,
                position: "absolute",
                top: 10,
                right: 15,
                cursor: "pointer",
                "&:hover": { bgcolor: "#EAEAEA" },
              }}
            >
              {copied == 2 ? (
                <Copied sx={{ fontSize: 22 }} />
              ) : (
                <Copy sx={{ fontSize: 22 }} />
              )}
            </Box>
            <Box>
              <Typography align="left" variant="h1">
                Sample Messages
              </Typography>
              <Markdown rehypePlugins={[rehypeRaw]}>
                {form_submissions_response.form_data.use_case_sample_messages}
              </Markdown>
            </Box>
          </Box>
        </Box>
        {/* [END] Use Case examples */}

        {/* [START] End User Consent */}
        <Box
          sx={{
            cursor: "auto",
            display: "flex",
            maxWidth: 1920,
            width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={Paper}
            sx={{
              bgcolor: "white",
              position: "relative",
              boxShadow: 12,
              p: 4,
              minWidth: "280px",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              onClick={() => {
                copy_text(
                  form_submissions_response.form_data.end_user_consent,
                  3
                );
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 21,
                width: 34,
                height: 34,
                position: "absolute",
                top: 10,
                right: 15,
                cursor: "pointer",
                "&:hover": { bgcolor: "#EAEAEA" },
              }}
            >
              {copied == 3 ? (
                <Copied sx={{ fontSize: 22 }} />
              ) : (
                <Copy sx={{ fontSize: 22 }} />
              )}
            </Box>
            <Box>
              <Markdown rehypePlugins={[rehypeRaw]}>
                {form_submissions_response.form_data.end_user_consent}
              </Markdown>
            </Box>
          </Box>
        </Box>
        {/* [END] Use Case examples */}

        {/* [START] Opt in keywords */}
        <Box
          sx={{
            cursor: "auto",
            display: "flex",
            maxWidth: 1920,
            width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={Paper}
            sx={{
              bgcolor: "white",
              position: "relative",
              boxShadow: 12,
              p: 4,
              minWidth: "280px",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              onClick={() => {
                copy_text(
                  form_submissions_response.form_data.opt_in_keywords,
                  4
                );
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 21,
                width: 34,
                height: 34,
                position: "absolute",
                top: 10,
                right: 15,
                cursor: "pointer",
                "&:hover": { bgcolor: "#EAEAEA" },
              }}
            >
              {copied == 4 ? (
                <Copied sx={{ fontSize: 22 }} />
              ) : (
                <Copy sx={{ fontSize: 22 }} />
              )}
            </Box>
            <Box>
              <Markdown rehypePlugins={[rehypeRaw]}>
                {form_submissions_response.form_data.opt_in_keywords}
              </Markdown>
            </Box>
          </Box>
        </Box>
        {/* [END] Opt in keywords */}

        {/* [START] Opt in keywords */}
        <Box
          sx={{
            cursor: "auto",
            display: "flex",
            maxWidth: 1920,
            width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          
          <Box
            component={Paper}
            sx={{
              bgcolor: "white",
              position: "relative",
              boxShadow: 12,
              p: 4,
              minWidth: "280px",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              onClick={() => {
                copy_text(
                  form_submissions_response.form_data.opt_in_message,
                  5
                );
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 21,
                width: 34,
                height: 34,
                position: "absolute",
                top: 10,
                right: 15,
                cursor: "pointer",
                "&:hover": { bgcolor: "#EAEAEA" },
              }}
            >
              {copied == 5 ? (
                <Copied sx={{ fontSize: 22 }} />
              ) : (
                <Copy sx={{ fontSize: 22 }} />
              )}
            </Box>
            <Box>
              <Markdown rehypePlugins={[rehypeRaw]}>
                {form_submissions_response.form_data.opt_in_message}
              </Markdown>
            </Box>
          </Box>
        </Box>
        {/* [END] Opt in keywords */}
        {/* [START] Opt in keywords */}
        <Box
          sx={{
            cursor: "auto",
            display: "flex",
            maxWidth: 1920,
            width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            onClick={() => {
              set_form_submissions_response(false);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 21,
              width: 34,
              height: 34,
              position: "absolute",
              top: 10,
              right: 15,
              cursor: "pointer",
              "&:hover": { bgcolor: "#EAEAEA" },
            }}
          >
            <Close />
          </Box>
          
        </Box>
        {/* [END] Opt in keywords */}
        <Box
            component={Paper}
            sx={{
              bgcolor: "white",
              position: "relative",
              boxShadow: 12,
              p: 4,
              minWidth: "280px",
              width: "100%",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Button
                  sx={{ mr: 2 }}
                  onClick={() => {
                    set_form_submissions_response(false);
                  }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    set_form_submissions_response(false);
                    window.scrollTo(0, 0);
                    set_empty_form(empty_form + 1);
                    set_alert(false);
                  }}
                  variant="contained"
                >
                  Create another form
                </Button>
              </Box>
              <Box sx={{ flex: 1, textAlign: "right" }}>
                <Button
                disabled={sent_a_copy_to_email}
                  variant="outlined"
                  onClick={() => {
                    if (!sent_a_copy_to_email){
                    send_a_copy_of_the_form_to_email();
                  }
                  }}
                >
                  {loading ? (
                    <CircularProgress />
                  ) : sent_a_copy_to_email ? (
                    "Sent ✅"
                  ) : (
                    "Send a copy of this submission to my email"
                  )}
                </Button>
              </Box>
            </Box>
        </Box>
      </Box>
    </CustomModal>
  );
}

export default RenderFormSubmissionsResponse;
