import { Box } from '@mui/system'
import React from 'react'

const Container = ({ children, bgcolor="#F9F9F9", sx, click_event }) => {
  return (
    <Box 
    onClick={click_event}
    sx={{
      py: 4,
      px: 2,
      transition:"0.2s ease",
      bgcolor: bgcolor, 
      borderRadius:6,
      margin: {xs:2, sm:0, md:0},
      border: "1px solid #EAEAEA",
      ...sx
    }}>
      {children}
    </Box>
  )
}

export default Container