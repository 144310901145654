import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Link, Typography, Grid, Tooltip } from '@mui/material';
import { axios_get_call, axios_post_call } from '../../components/AxiosCall';
import Container from '../../components/Container';
import LoadingScreen from '../../components/LoadingScreen';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


const useScript = (code) => {
    useEffect(() => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = code;
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, [code]);
  };
  
const Subscription = ({ user }) => {
    const [alert, setAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stripeBillingDashboardAddress, setStripeBillingDashboardAddress] = useState(false);
    const [hasSubscription, setHasSubscription] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState(false);
    const [inAccountCreation, setInAccountCreation] = useState(false);
    const [opened, setOpened] = useState(false);
    const [subscription_prices, set_subscription_prices] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    const scriptCode = `
    window.gtag_event('event', 'ads_conversion_Begin_checkout_Page_loa_1', {
      // <event_parameters>
    });
  `;

  useScript(scriptCode);

    function parse_timestamp(timestamp) {
        const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
        const formattedDate = date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: '2-digit' });
        return formattedDate
    }


    useEffect(() => {
        if (location.pathname.includes("account-setup")) {
            setInAccountCreation(true);
        }
    }, [location]);

    useEffect(() => {
        getStripeBillingDashboardAddress();
    }, []);

    useEffect(() => {
        if (user && !opened && stripeBillingDashboardAddress && user.subscription_data) {
            setHasSubscription(true);
            setOpened(true);
        }
    }, [user, stripeBillingDashboardAddress]);

    useEffect(() => {
        if (!localStorage.getItem('access')) {
            navigate('/');
        }
    }, []);

    const handlePlanSelection = (type) => {
        setSubscriptionType(type);
    }

    const get_subscription_prices = async () => {
        setLoading(true);
        let url = '/subscription/get-subscription-prices/';
        let res = await axios_post_call(url, {  }, setLoading, setAlert);

        if (res.data.status === 1) {
            setLoading(false);
            set_subscription_prices(res.data.response);
        } else if (res.data.status === 0) {
            setAlert(res.data.response);
            setLoading(false);
        }
    }
    const createCheckoutSession = async () => {
        setLoading(true);
        let url = '/subscription/create-checkout-session/';
        let res = await axios_post_call(url, { subscription_type: subscriptionType }, setLoading, setAlert);

        if (res.data.status === 1) {
            setLoading(false);
            window.open(res.data.response, "_blank");
        } else if (res.data.status === 0) {
            setAlert(res.data.response);
            setLoading(false);
        }
    }

    const getStripeBillingDashboardAddress = async () => {
        setLoading(true);
        let url = '/subscription/get-stripe-billing-dashboard-address/';
        let res = await axios_get_call(url, {}, setLoading, setAlert);

        if (res.data.status === 1) {
            setLoading(false);
            setStripeBillingDashboardAddress(res.data.response);
        } else if (res.data.status === 0) {
            setAlert(res.data.response);
            setLoading(false);
        }
    }

    useEffect(() => {
        get_subscription_prices()
    }, []);

    return (
        user ?
            <Box sx={{maxWidth: 1920,
                width:{xs:"95%", sm:"90%", md:600, lg:800, xl:1000},
                minHeight:"100vh",
                m:"auto"}}>
                <LoadingScreen loading={loading} />
                {hasSubscription ?
                    <Container bgcolor={"white"}>
                        
        <Typography variant="h2" mb={2} align='center'>
          Subscription Details
        </Typography>
                        {user && <Box sx={{px:2}}>
                            <Typography mb={1} variant='subtitle2'><strong>Status:&nbsp;</strong>{user.subscription_data.status}</Typography>
                            <Typography mb={1} variant='subtitle2'><strong>Start Date:&nbsp;</strong>{parse_timestamp(user.subscription_data.current_period_start)}</Typography>
                            <Typography mb={1} variant='subtitle2'><strong>Your plan renews on:&nbsp;</strong>{parse_timestamp(user.subscription_data.current_period_end)}</Typography>
                            <Typography mb={1} variant='subtitle2'><strong>Type:&nbsp;</strong>{user.subscription_data.type}</Typography>
                        </Box>}
                        <Box sx={{ display: "flex" }}>
                            <Button sx={{ ml:2, my: 1 }} variant='contained' onClick={() => {
                                window.open(stripeBillingDashboardAddress['url'], "_blank")
                            }}>Manage subscription</Button>
                            <Button sx={{ m:1 }} variant='outlined' onClick={() => {
                                navigate('/')
                            }}>Go back to home page</Button>
                        </Box>
                    </Container>
                    :
                    <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
                        <Container bgcolor="white" sx={{ maxWidth: 800, width: "100%", px: 4, py: 4 }}>
                            <Typography align='center' variant='h4'>Get Unlimited Use</Typography>
                            <Typography align='center' variant='subtitle2'>Please select one of our plans</Typography>
                            <br />
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} md={6} >
                                    <Box sx={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", minHeight:'100px', border: subscriptionType === "monthly" ? "3px solid" : "3px solid #ededed", borderColor: subscriptionType === "monthly" && "primary.main", p: 2, textAlign: 'center', cursor: "pointer", borderRadius: 2 }} onClick={() => handlePlanSelection("monthly")}>
                                        <Typography fontWeight={"bold"} variant='h6'>${subscription_prices.monthly_subscription_price} per month</Typography>
                                        <Typography variant='body1'>Unlimited A2P generations for a month</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <Box sx={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", minHeight:'100px', border: subscriptionType === "yearly" ? "3px solid" : "3px solid #ededed", borderColor: subscriptionType === "yearly" && "primary.main", p: 2, textAlign: 'center', cursor: "pointer", borderRadius: 2 }} onClick={() => handlePlanSelection("yearly")}>
                                        <Typography fontWeight={"bold"} variant='h6'>${subscription_prices.yearly_subscription_price} per year - <span>{subscription_prices.discount_percentage}% OFF</span> </Typography>
                                        <Typography variant='subtitle2' sx={{fontSize:12}}>${subscription_prices.yearly_subscription_price/12} per month, billed annually</Typography>
                                        <Typography variant='body1'>Unlimited A2P generations for a year</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />

                                <Tooltip title={!subscriptionType ? "Please select any subscription plan first" : ""}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                <Button disabled={!subscriptionType} color="primary" variant='contained' onClick={createCheckoutSession}>{loading ? <CircularProgress color='secondary' /> : "Proceed to Checkout"}</Button>
                            </Box>
                                </Tooltip>
                            {alert ? <Alert severity={alert.toLowerCase().includes('[error]') ? 'error' : 'success'} sx={{ mt: 2 }}>{alert}</Alert> : null}
                        </Container>
                    </Box>
                }
                <Box sx={{
                    display:"flex",
                    justifyContent:"center",
                    width:1,
                    ml:1.5,
                    my:3
                }}>
                <Button sx={{mr:1}} color="secondary" mt={5} variant="contained" onClick={()=>{navigate("/")}}>Back to A2P Generator</Button>
                </Box>
            </Box> : null
    );
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Subscription);
