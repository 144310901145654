import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { load_user } from "../actions/auth";
import { Box, Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import theme from "./ThemeConfig";
import AppSidebar from "../components/AppSidebar";
import { axios_get_call } from "../components/AxiosCall";
import FeedbackForm from "./FeedbackForm";

const Layout = ({ load_user, children }) => {
  const location = useLocation().pathname;
  const [admin_panel, set_admin_panel] = useState(false);

  const [loading, set_loading] = useState(false);
  const [alert, set_alert] = useState(false);
  const [global_variables, set_global_variables] = useState(false);
  const [background_image, set_background_image] = useState("");
  const [background_color, set_background_color] = useState("");

  const get_website_global_variables = async (e) => {
    let url = "/system/get-website-global-variables/";
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_global_variables(res.data.response);
      set_background_image(
        res.data.response &&
          process.env.REACT_APP_BACKEND_URL.split("/api")[0] +
            res.data.response.background_image
      );
      set_background_color(
        res.data.response && res.data.response.background_color
      );

      set_loading(false);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  useEffect(() => {
    get_website_global_variables();
  }, []);

  useEffect(() => {
    load_user();
  }, []);

  useEffect(() => {
    if (location.includes("admin-panel")) {
      set_admin_panel(true);
    } else {
      set_admin_panel(false);
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{ bgcolor: "#F9F9F9", boxShadow: 0 }}>
        {admin_panel ? (
          <AppSidebar
            children={
              <Box
                sx={{
                  minHeight: "100vh",
                  bgcolor: "#F3F3F9",
                }}
              >
                {children}
              </Box>
            }
          />
        ) : (
          <>
            <Box
              sx={
                background_color
                  ? {
                      minHeight: "100vh",
                      bgcolor: background_color,
                    }
                  : {
                      minHeight: "100vh",
                      // backgroundImage: 'url("https://source.unsplash.com/random/1920x1080/?gradeitn%20grey%20background")',
                      backgroundImage: `url(${background_image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "calc(100%) calc(100% - 50px)"

                    }
              }
            >
              <Navbar />
              <FeedbackForm />
              <Box sx={{ pb: "100px" }}></Box>
              <Box sx={{ maxWidth: "1920px", m: "auto", minHeight:"100vh" }}>{children}</Box>

              <Footer />
            </Box>
          </>
        )}
      </Paper>
    </ThemeProvider>
  );
};

export default connect(null, { load_user })(Layout);
