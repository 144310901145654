import React, { useEffect, useState } from 'react';
import { Alert, Box, Typography, Grid, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { axios_get_call } from '../../components/AxiosCall';

import LoggedInUsersTodayIcon from '@mui/icons-material/Login';
import LoggedInUsersYesterdayIcon from '@mui/icons-material/Input';
import TotalUsersIcon from '@mui/icons-material/People';
import TotalFormSubmissions from '@mui/icons-material/FormatColorText';
import SubscribedUsers from '@mui/icons-material/CardMembership';
import TotalMonthlySubscriptions from '@mui/icons-material/CalendarMonthSharp';
import TotalYearlySubscriptions from '@mui/icons-material/TodaySharp';
import UnreadContactFormEntries from '@mui/icons-material/MarkChatUnreadSharp';


const AdminPanel = ({ user }) => {
    const navigate = useNavigate();

    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [overview, setOverview] = useState({
        "logged_in_users_today": 5,
        "logged_in_users_yesterday": 6,
        "total_users": 6,
        "total_form_submissions": 11,
        "subscribed_users": 5,
        "total_monthly_subscriptions": 4,
        "total_yearly_subscriptions": 1,
        "unread_contact_form_entries": 12,
    });

    const [loggedInUsersData, setLoggedInUsersData] = useState([
        { day: 'Mon', logged_in_users: 10 },
        { day: 'Tue', logged_in_users: 8 },
        { day: 'Wed', logged_in_users: 12 },
        { day: 'Thu', logged_in_users: 15 },
        { day: 'Fri', logged_in_users: 9 },
        { day: 'Sat', logged_in_users: 11 },
        { day: 'Sun', logged_in_users: 13 }
    ]);
    const getOverviewData = async () => {
        let url = '/saas/admin-panel/get-overview/';
        try {
            const res = await axios_get_call(url, {}, setLoading, setAlert);
            if (res.data.status === 1) {
                setOverview(res.data.response);
            } else if (res.data.status === 0) {
                setAlert(res.data.response);
            }
        } catch (error) {
            console.error("Error fetching overview data:", error);
            setAlert("Failed to fetch data. Please try again later.");
        }
    };

    function get_corresponding_icon(item_name) {

        switch (item_name) {
            case "logged_in_users_today":
                return <LoggedInUsersTodayIcon sx={{ color: "secondary.dark" }} />
                break;
            case "logged_in_users_yesterday":
                return <LoggedInUsersYesterdayIcon sx={{ color: "secondary.dark" }} />
                break;

            case "total_users":
                return <TotalUsersIcon sx={{ color: "secondary.dark" }} />
                break;

            case "total_form_submissions":
                return <TotalFormSubmissions sx={{ color: "secondary.dark" }} />
                break;

            case "subscribed_users":
                return <SubscribedUsers sx={{ color: "secondary.dark" }} />
                break;
            case "total_monthly_subscriptions":
                return <TotalMonthlySubscriptions sx={{ color: "secondary.dark" }} />
                break;
            case "total_yearly_subscriptions":
                return <TotalYearlySubscriptions sx={{ color: "secondary.dark" }} />
                break;
            case "unread_contact_form_entries":
                return <UnreadContactFormEntries sx={{ color: "secondary.dark" }} />
                break;

            default:
                return <LoggedInUsersTodayIcon sx={{ color: "secondary.dark" }} />
                break;
        }
    }

    useEffect(() => {
        // Fetch data when component mounts
        // getOverviewData();
    }, []);

    useEffect(() => {
        // Redirect if user is not authenticated
        if (!localStorage.getItem('access')) {
            navigate('/');
        }
    }, []);

    return (
        user ? (
            <Box sx={{ mt: 4 }}>
                {alert && (
                    <Alert
                        onClose={() => setAlert('')}
                        severity={alert.toLowerCase().includes('[error]') ? 'error' : 'success'}
                        sx={{ mb: 3 }}
                    >
                        {alert}
                    </Alert>
                )}
                <Typography sx={{ fontSize: 18, mb: 0 }} align="left" gutterBottom>Hey {user.name}! </Typography>
                <Typography variant="subtitle1" align="left" color="text.secondary" gutterBottom>Here's what's happening!</Typography>
                <br />
                <Grid container spacing={3}>
                    {Object.keys(overview).map((key, index) => (
                        <Grid item xs={12} md={6} lg={4} key={index}>
                            {/* linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) */}

                            <Paper sx={{ transition: "all .4s", p: 2, boxShadow: "0 1px 2px rgba(56,65,74,0.15)", borderRadius: 1, background: "white", minWidth: 280 }}>
                                <Typography variant="subtitle2" color="text.secondary" align="left" sx={{ fontWeight: "500" }} gutterBottom>{key.replace(/_/g, ' ').toUpperCase()}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h5" align="left" sx={{ fontWeight: "600", color: "#212529" }} >{overview[key]}</Typography>
                                    </Box>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        p: 1.25,
                                        borderRadius: 3,
                                        bgcolor: "rgb(208, 119, 54, 0.2)"

                                    }}>
                                        {get_corresponding_icon(key)}
                                    </Box>


                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                    <br />
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="subtitle2" color="text.secondary" align="left" gutterBottom>Logged in Users (Last 7 Days)</Typography>
                        <Paper sx={{ p: 2, boxShadow: "0 1px 2px rgba(56,65,74,0.15)", borderRadius: 1, background: "white" }}>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={loggedInUsersData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="day" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="logged_in_users" stroke="#893C03" label={true} fill="white" isAnimationActive={true}  strokeWidth={3} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Box >
        ) : null
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(AdminPanel);
