import React, { useEffect, useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'

import { axios_get_call } from '../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Users = ({ user }) => {
    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [something, set_something] = useState(false);

    const navigate = useNavigate()

    const get_something = async () => {
        let url = '/saas/admin-panel/get-something/';
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_something(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        // get_something()
    }, [])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/')
        }
    }, [])

    return (
        user ? <Box>

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'success'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <Typography align='center'>Users</Typography>
            <Typography align='center'>
                {typeof something}
            </Typography>
        </Box> : ''
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Users);