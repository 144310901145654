import React, { useEffect, useState } from "react";
import {
  Link,
  Box,
  Alert,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
} from "@mui/material";

import { axios_post_call } from "../../components/AxiosCall"; // Import your Axios utility function
import { connect } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { load_user } from "../../actions/auth";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function Profile({ user, load_user }) {
  const [formData, setFormData] = useState({
    email: user && user.email,
    name: user && user.name,
    company_name: user && user.company_name,
  });
  const navigate = useNavigate();

  const [edited, set_edited] = useState(false);
  const [loading, set_loading] = useState(false);
  const [alert, set_alert] = useState("");
  const [phone, set_phone] = useState("");

  function parse_timestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
    return formattedDate;
  }
  const handleChange = (e) => {
    set_edited(true);
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    set_loading(true);
    formData["phone"] = phone;
    let url = "/auth/update-settings/";
    let res = await axios_post_call(url, formData, set_loading, set_alert);

    if (res.data.status === 1) {
      set_loading(false);
      set_alert(res.data.response, "_blank");
      load_user();
    } else if (res.data.status === 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("access")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    set_edited(true);
  }, [phone]);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        email: user.email,
        company_name: user.company_name,
      });
      set_phone(user.phone);
    }
  }, [user]);

  return (
    user && (
      <Box
        sx={{
          maxWidth: 1920,
          width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
          m: "auto",
          minHeight: "100vh",
        }}
      >
              <style
        dangerouslySetInnerHTML={{
          __html: `.PhoneInputCountry {
            display:none
        }`,
        }}
      />
        <Box
          component={Paper}
          sx={{
            p: 5,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
            bgcolor: "white",
            background: "linear-gradient(45deg, #f0f0f0 30%, #ffffff 90%)",
          }}
        >
          <Typography align="center" variant="h2" m={0} p={0}>
            Hi {user.name},
          </Typography>

          <Box>
            <Typography variant="h3" mb={1} fontWeight="bold">
              Subscription
            </Typography>
            {user && user.subscription_data ? (
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">
                      <strong>Status:</strong> {user.subscription_data.status}
                    </Typography>
                    <Typography variant="subtitle2">
                      <strong>Type:</strong> {user.subscription_data.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2">
                      <strong>Start Date:</strong>{" "}
                      {parse_timestamp(
                        user.subscription_data.current_period_start
                      )}
                    </Typography>
                    <Typography variant="subtitle2">
                      <strong>Your plan renews on:</strong>{" "}
                      {parse_timestamp(
                        user.subscription_data.current_period_end
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Link
                  component={RouterLink}
                  to="/subscription"
                  variant="subtitle2"
                >
                  Manage subscription
                </Link>
                {/* Add Graphs or Charts if needed */}
              </Box>
            ) : (
              <Button
                sx={{ mr: 1 }}
                variant="outlined"
                onClick={() => {
                  navigate("/subscription");
                }}
              >
                Get Unlimited Use
              </Button>
            )}
          </Box>
          <Box>
            <Typography variant="h3" mt={2} mb={1} fontWeight="bold">
              Account info
            </Typography>

            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 1.5 }}></Box>
              <TextField
                sx={{ bgcolor: "white" }}
                label="Name"
                variant="outlined"
                size="small"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
              />
              <Box sx={{ mt: 1.5 }}></Box>
              <TextField
                sx={{ bgcolor: "white" }}
                label="Company Name"
                variant="outlined"
                size="small"
                name="company_name"
                value={formData.company_name}
                onChange={handleChange}
                fullWidth
              />
              <Box sx={{ mt: 1.5 }}></Box>
              <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                onChange={set_phone}
                inputComponent={PhoneInputTextField}
                country="US"

                    flags={false}
                    flag_url={false}
                defaultCountry="US" // You can set the default country here
              />
              <Box sx={{ mt: 1.5 }}></Box>
              <TextField
                sx={{ bgcolor: "white" }}
                label="Email"
                variant="outlined"
                size="small"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
              />
              <Box sx={{ mt: 1 }}></Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ mr: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/reset-password");
                    }}
                  >
                    Reset Password
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loading || !edited}
                  >
                    {loading ? "Updating..." : "Update"}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
          {alert ? (
            <Alert
              severity={
                alert.toLowerCase().includes("[error]") ? "error" : "success"
              }
              sx={{ mt: 2 }}
            >
              {alert}
            </Alert>
          ) : null}
        </Box>
      </Box>
    )
  );
}
const PhoneInputTextField = React.forwardRef(
  ({ value, onChange, ...props }, ref) => (
    <TextField
      {...props}
      inputRef={ref}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      type="tel"
      size="small"
      inputProps={{ maxLength: 16 }}
      sx={{ bgcolor: "white" }}
    />
  )
);

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { load_user })(Profile);
