import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./hocs/Layout";
import './App.css';

// Main containers
import SaaSPage from "./containers/main/SaaSPage";
import ThankYou from "./containers/main/ThankYou";
import PaymentFailed from "./containers/main/PaymentFailed";
import PageNotFound from "./containers/main/PageNotFound";



// Authentication containers
import Activate from "./containers/authentication/Activate";
import ResetPassword from "./containers/authentication/ResetPassword";
import ResetPasswordConfirm from "./containers/authentication/ResetPasswordConfirm";

// SaaS containers
import Subscription from "./containers/saas/Subscription";
import Profile from "./containers/saas/Profile";

// Admin Panel containers
import AdminPanel from "./containers/admin_panel/AdminPanel";
import Users from "./containers/admin_panel/Users";

// Redux Store
import { Provider } from "react-redux";
import store from "./store";



function App() {
  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            {/* // Base routes */}
            <Route path="*" element={<PageNotFound />} />
            <Route path="/" element={<SaaSPage />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/payment-failed" element={<PaymentFailed />} />
            {/* // Authentication routes */}
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              exact
              path="/account-activation-email-sent"
              element={<ResetPassword />}
            />
            <Route
              exact
              path="/password/reset/confirm"
              element={<ResetPasswordConfirm />}
            />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route exact path="/activate" element={<Activate />} />
            
            {/* // SaaS containers */}
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/subscription" element={<Subscription />} />

            {/* // Admin Panel containers */}
            <Route exact path="/admin-panel" element={<AdminPanel />} />
            <Route exact path="/admin-panel/overview" element={<AdminPanel />} />
            <Route exact path="/admin-panel/users" element={<Users />} />
          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
}

export default App;
