import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import Container from "../../components/Container";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


const useScript = (code) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = code;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [code]);
};


function ThankYou() {
  const [show_Confetti, set_show_Confetti] = useState(true); // State to control showing/hiding Activate component
  const navigate = useNavigate();

  const scriptCode = `
  window.gtag_event('event', 'ads_conversion_Purchase_Page_load_app__1', {
    // <event_parameters>
  });
`;

useScript(scriptCode);



  useEffect(() => {
    setTimeout(() => {
      set_show_Confetti(false);
    }, 3000); // 3 seconds
  }, []);

  return (
    <Box sx={{ maxWidth: 800, m: "auto" }}>
     
      {show_Confetti && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50vh",
            width: "50%",
            height: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {show_Confetti && <ConfettiExplosion />}
        </Box>
      )}

      <Container sx={{ textAlign: "center" }}>
        <Typography align="center" variant="h1">
          Thank you!
        </Typography>
        <Typography align="center" variant="subtitle1" color="grey.700">
          Your plan was successfully activated. Enjoy unlimited use.
          <br />
          if you have any feedback to send us, use the button in the bottom
          right corner.
        </Typography>
        <br />
        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
        >
          Start Now
        </Button>
      </Container>
    </Box>
  );
}

export default ThankYou;
