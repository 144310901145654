// Activate.jsx
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { verify } from "../../actions/auth";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";

const Activate = ({user, verify }) => {
  const [token, set_token] = useState('');
  const [loading, set_loading] = useState('');
  const navigate = useNavigate();

  const verifyAccount = async () => {
    set_loading(true)
    await verify(token);
    set_loading(false)
    set_token('')
  };

  useEffect(() => {
    if (user) {
      if (localStorage.getItem("access")) {
        navigate(`/`);
      }
    }
  }, [user]);

  return (
    <Box sx={{
      display: "flex",
      flexDirection:"column",
      justifyContent: "center",
      alignItems: "center",
      my:4
    }}>
        <Typography variant="h1" marginTop={2}>
          Activate Your Account
        </Typography>
        <Typography variant="subtitle2" marginTop={2} align='center'>
        A 6-digit code has been sent to your email. Please copy and paste it here to register
        </Typography>
        <br />
        <Box
          sx={{
            flexGrow: 1,
            width: "100%",
            textAlign: "-webkit-center",
          }}
        >
          <TextField
            label="Activation code"
            variant="outlined"
            value={token}
            onChange={(e) => set_token(e.target.value)}
            style={{ marginBottom: '16px',  }}
          />
          <Button onClick={verifyAccount} sx={{height:"60px", maxHeight:'60px'}} variant="contained" type="button">
            {loading ? <CircularProgress size={24} /> : "Verify"}

          </Button>
        </Box>
    </Box>
  );
};


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {verify})(Activate);
  