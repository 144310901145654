import React, { useState } from "react";
import Login from "../authentication/Login";
import Signup from "../authentication/Signup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaaSForm from "./SaaSForm";

import { connect } from "react-redux";
import { Divider, Paper, Typography } from "@mui/material";

function SaaSPage({ user }) {
  const [activeTab, set_active_tab] = useState("login");

  const handleTabChange = (tab) => {
    set_active_tab(tab);
  };

  return (
    <Box
      sx={{
        maxWidth: 1920,
        width: { xs: "95%", sm: "90%", md: 600, lg: 800, xl: 1000 },
        m: "auto",
        minHeight: "100vh",
      }}
    >
      {!user && (
        <Box
          component={Paper}
          sx={{
            p: 5,
            minWidth: 280,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
            bgcolor: "white",
            background: "linear-gradient(45deg, #f0f0f0 30%, #ffffff 90%)",
          }}
        >
          <Box>
            <Box sx={{ width: 1, display: "flex" }}>
              <Box sx={{ width: 1, flex: 1 }}>
                <Button
                  fullWidth
                  onClick={() => handleTabChange("login")}
                  variant={activeTab === "login" ? "contained" : "outlined"}
                  color="primary"
                  sx={{
                    borderRadius: "20px 0 0 0",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                      color: "white",
                    },
                  }}
                >
                  Login
                </Button>
              </Box>
              <Box sx={{ width: 1, flex: 1 }}>
                <Button
                  fullWidth
                  onClick={() => handleTabChange("register")}
                  variant={activeTab === "register" ? "contained" : "outlined"}
                  color="primary"
                  sx={{
                    borderRadius: "0 20px 0 0",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.primary.dark,
                      color: "white",
                    },
                  }}
                >
                  Register
                </Button>
              </Box>
            </Box>

            {activeTab === "login" ? (
              <Login set_active_tab={set_active_tab} />
            ) : (
              <Signup set_active_tab={set_active_tab} />
            )}
          </Box>
        </Box>
      )}

      <Box
        component={Paper}
        sx={{
          mt: 5,
          mb: 15,
          p: 5,
          minWidth: 280,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
          bgcolor: "blue",
        }}
      >
        <SaaSForm />
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(SaaSPage);
