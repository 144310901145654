import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import {
	Box,
	Button,
	Typography,

} from "@mui/material";

import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


import { axios_get_call } from "./AxiosCall";
import LoadingScreen from "./LoadingScreen";

const Navbar = ({ logout, user }) => {

	const navigate = useNavigate();
	const [loading, set_loading] = useState(false)
	const [alert, set_alert] = useState(false)
	const [global_variables, set_global_variables] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null);


	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};


	const get_website_global_variables = async (e) => {
		let url = '/system/get-website-global-variables/';
		let res = await axios_get_call(url, {}, set_loading, set_alert);

		if (res.data.status == 1) {
			set_global_variables(res.data.response);
			set_loading(false);

		} else if (res.data.status == 0) {
			set_alert(res.data.response);
			set_loading(false);
		}
	}

	useEffect(() => {
		get_website_global_variables()
	}, [])


	return (
		<Box sx={{ color: "white", bgcolor: global_variables && global_variables.navbar_color, backdropFilter: "blur(15px)" }}>
			  <Box sx={{maxWidth: 1920, m:"auto"}}>
			<LoadingScreen loading={loading} />
			<Box sx={{ p: 2,  }}>

				<Box sx={{ maxWidth: "1920px", m: "auto", display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
					<Box onClick={() => { navigate("/") }} sx={{ cursor: "pointer", flex: 1, display: 'flex', maxWidth: global_variables && global_variables.max_width_for_logo }}>
						<img src={global_variables && process.env.REACT_APP_BACKEND_URL.split("/api")[0] + global_variables.logo} width="100%" />
					</Box>
					<Box sx={{ flex: 1, display: 'flex', alignItems: "center", justifyContent: "right" }}>
						{user && (
							<Box>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenu}
									color="inherit"
								>
								{user && user.profile_pic ?
									<Avatar sx={{ width: 30, height:30 }}  alt={user.name} src={user.profile_pic} />
									:
									<AccountCircle sx={{ fontSize: 30 }} />
								}
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorEl)}
									onClose={() => {
										handleClose()

									}}
									PaperProps={{

									sx:{width: 280, ml:-6, mt:3, py:1 }
									}}
								>
									<MenuItem onClick={() => {
										handleClose()
										navigate("/profile")
									}}>
										<Typography variant="subtitle2" fontSize={18} color="grey.750">Profile</Typography>
										</MenuItem>
									<MenuItem onClick={() => {
										handleClose()
										navigate("/subscription")
									}}>
										<Typography variant="subtitle2" fontSize={18} color="grey.750"> Manage Subscription</Typography>
										</MenuItem>

									{user.is_superuser &&
										<MenuItem onClick={() => {
											handleClose()
											// navigate("/admin-panel")	
											window.open(process.env.REACT_APP_BACKEND_URL.split("/api")[0]+"/admin/", "_blank")
										}}>
											<Typography variant="subtitle2" fontSize={18} color="grey.750"> Admin Panel</Typography>
											</MenuItem>
									}
									{user &&
										<MenuItem onClick={() => {
											handleClose()
											logout()
											navigate("/")

										}}>
											<Typography variant="subtitle2" fontSize={18} color="grey.750"> Logout</Typography>
											</MenuItem>
									}
<br/>
									{user && 
									<Typography align="center" sx={{px:2}} variant="subtitle2" color="text.secondary">{user.email}</Typography>
								}

								</Menu>
							</Box>
						)}
					</Box>
					{/* {user &&
						<Box sx={{ display: 'flex', alignItems: "center" }}>
							<Link component={RouterLink} sx={{ cursor: "pointer" }} to="/subscription" variant="subtitle2" sx={{ color: "white" }}>
								Manage Subscription
							</Link>
							{user.is_superuser &&
								<Button sx={{ ml: 1 }} variant='contained' color="secondary" onClick={() => { navigate("/admin-panel") }}>Admin Panel</Button>
							}
							<Button sx={{ ml: 1 }} variant='contained' onClick={() => { logout() }}>Logout</Button>
						</Box>
					} */}
				</Box>
			</Box>

			{alert && (
				<Alert
					onClose={() => set_alert(false)}
					severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
					sx={{ m: "auto", zIndex: 9923213 }}
				>
					{alert}
				</Alert>
			)}
		</Box>
		</Box>
	);
};
// [End] main function to render navbar
const mapStateToProps = (state) => ({
	state: state,
	user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Navbar);
