import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password_confirm } from "../../actions/auth";
import queryString from "query-string";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "../../components/Container";
import CustomModal from "../../components/CustomModal";
import Button from "@mui/material/Button";
import { Alert } from "@mui/material";

const ResetPasswordConfirm = ({ reset_password_confirm }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [alert, set_alert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const onChange = (e) => setPassword(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const queryValues = queryString.parse(location.search);
    const uidb64 = queryValues.uidb64 || null;
    const token = queryValues.token || null;

    let res = await reset_password_confirm(uidb64, token, password, setSuccess, set_alert);
    setLoading(false);
    
  };

  return (
    <Container bgcolor="white" sx={{ maxWidth: "700px", margin: "auto", padding: "20px" }}>
      <Typography variant="h1" align="center">
        Change Your Password
      </Typography>
      <Typography variant="subtitle1" textAlign="center">
        Create a new password that you will use to log in.
      </Typography>
      <Box marginTop={4}>
        <form onSubmit={(e) => onSubmit(e)} style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            type="password"
            margin="dense"
            value={password}
            onChange={(e) => onChange(e)}
            label="New Password"
            id="outlined-start-adornment"
            sx={{ width: "100%", marginBottom: "16px" }}
            minLength="6"
            required
          />
         {alert ? (
                <Alert severity="error">
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {alert}
                  </Typography>
                </Alert>
              ) : (
                <Box mb={1}>
                  <Typography variant="subtitle2" color="error" m={0} p={0}>
                    Password Requirements:
                  </Typography>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <Typography sx={{fontSize:{xs:10, sm:12, md:"initial"}}} variant="subtitle2" color="text.secondary">
                      <Box sx={{ display: "inline",  }}>
                        {/* Original condition for password length */}
                        {password.length >= 8 ? "✅" : "❌"}
                      </Box>{" "}
                      Minimum 8 characters
                    </Typography>
                    <Typography sx={{fontSize:{xs:10, sm:12, md:"initial"}}} variant="subtitle2" color="text.secondary">
                      <Box sx={{ display: "inline",  }}>
                        {/* Check if all characters are lowercase alphabets */}
                        {/^[a-z]+$/.test(password)
                          ? "❌"
                          : password.length >= 1
                          ? "✅"
                          : "❌"}
                      </Box>{" "}
                      One number or uppercase character
                    </Typography>

                    <Typography sx={{fontSize:{xs:10, sm:12, md:"initial"}}} variant="subtitle2" color="text.secondary">
                      <Box sx={{ display: "inline",  }}>
                        {/* Check for entirely numeric password */}
                        {/^\d+$/.test(password)
                          ? "❌"
                          : password.length >= 1
                          ? "✅"
                          : "❌"}
                      </Box>{" "}
                      Password should not be entirely numeric
                    </Typography>
                  </ul>
                </Box>
              )}
              <Box sx={{mb:2}}>
              </Box>
          <Button type="submit" variant="contained" fullWidth disabled={loading}>
            {loading ? "Loading..." : "Reset Password"}
          </Button>
        </form>
      </Box>
      <Box mb={2}>
      </Box>
     
      {success && (
       <CustomModal set_preview={setSuccess}>
        <Box sx={{px:8, py:5, bgcolor:"white", borderRadius:6}}>
          <Typography variant="h5">{success}</Typography>
          <Box sx={{ marginTop: "10px" }}>
        
            <Button variant="contained" onClick={() => navigate("/")}>
            Back to A2P Generator
            </Button>
          </Box>
          </Box>
      </CustomModal>
      )}
        </Container>
  );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
