import axios from "axios";
import csrf_grabber from "../components/csrf_grabber";

import { useNavigate } from "react-router-dom";
import { alert_box } from "../components/alert_structure";
import {
  LOADING,
  ALERT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_AUTH_FAIL,
  LOGOUT,
} from "./types";
import Alert from "@mui/material/Alert";
// import gtagEvent from "../components/grab_user_analytics";
var csrftoken = csrf_grabber("csrftoken");


export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "X-CSRFToken": csrftoken,
        "Authorization": `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    const body = JSON.stringify({
      access_token: localStorage.getItem("access"),
    });
    try {
      dispatch({
        type: LOADING,
        payload: { loading_status: true },
      });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/get-user-data/`,
        body,
        config
      );
      if (res.data.status == 401) {
        dispatch({
          type: ALERT,
          payload: { alert_message: "User doesn't exist" },
        });
      }
      if (res.data.status == 1) {
        dispatch({
          type: USER_LOADED_SUCCESS,
          payload: res.data.response,
        });
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });


      } else if (res.data.status == 0) {
        dispatch({
          type: ALERT,
          payload: { alert_message: res.data.response },
        });

        dispatch({
          type: LOGOUT,
        });

        dispatch({
          type: USER_LOADED_FAIL,
        });
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: LOGOUT,
      });

      dispatch({
        type: USER_LOADED_FAIL,
      });
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: LOGOUT,
    });

    dispatch({
      type: USER_LOADED_FAIL,
    });
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const delete_user = () => async (dispatch) => {

  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "X-CSRFToken": csrftoken,
        "Authorization": `Bearer ${localStorage.getItem("access")}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    const body = JSON.stringify({
      access_token: localStorage.getItem("access"),
    });
    try {
      dispatch({
        type: LOADING,
        payload: { loading_status: true },
      });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/delete-user-data/`,
        body,
        config
      );
      if (res.data.status == 1) {
        dispatch({
          type: ALERT,
          payload: { alert_message: "User deleted" },
        });
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        dispatch(load_user());
        // gtagEvent("DELETE", "USER", "Delete User", 1)
      } else if (res.data.status == 0) {
        dispatch({
          type: ALERT,
          payload: { alert_message: res.data.response },
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
};


export const googleAuthenticate = (accessToken) => async (dispatch) => {
  if (!localStorage.getItem("access")) {
    const config = {
      headers: {
        "X-CSRFToken": csrftoken,
        "Content-Type": "application/json",
      },
    };
    const inputs = JSON.stringify({
      token: accessToken,
      backend: "google-oauth2",
      grant_type: "convert_token",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret:
        process.env.REACT_APP_CLIENT_SECRET,
    });
    try {
      dispatch({
        type: LOADING,
        payload: { loading_status: true },
      });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/convert-token`,
        inputs,
        config
      );

      dispatch({
        type: GOOGLE_AUTH_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: ALERT,
        payload: { alert_message: "You have logged in with Google successfully" },
      });
      // gtagEvent("Google Login", "AUTH", "Login User", 1);
      dispatch(load_user());
    } catch (err) {
      console.log(err);
      dispatch({
        type: GOOGLE_AUTH_FAIL,
      });
      dispatch({
        type: ALERT,
        payload: { alert_message: "Cannot login with Google" },
      });
    }
  }
};

export const facebookAuthenticate = (accessToken) => async (dispatch) => {
  if (!localStorage.getItem("access")) {
    const config = {
      headers: {
        "X-CSRFToken": csrftoken,
        "Content-Type": "application/json",
      },
    };
    const inputs = JSON.stringify({
      token: accessToken,
      backend: "facebook",
      grant_type: "convert_token",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret:
        process.env.REACT_APP_CLIENT_SECRET,
    });

    try {
      dispatch({
        type: LOADING,
        payload: { loading_status: true },
      });
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/convert-token`,
        inputs,
        config
      );

      dispatch({
        type: FACEBOOK_AUTH_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: ALERT,
        payload: { alert_message: "You have logged in with Facebook successfully" },
      });

      // gtagEvent("Facebook Login", "AUTH", "Login User", 1);
      dispatch(load_user());

    } catch (err) {
      dispatch({
        type: FACEBOOK_AUTH_FAIL,
      });
      dispatch({
        type: ALERT,
        payload: { alert_message: "Cannot login with Facebook" },
      });
    }
  }
};

export const login = (email, password, setAlert) => async (dispatch) => {
  const config = {
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    username: email,
    password: password,
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret:
      process.env.REACT_APP_CLIENT_SECRET,
  });

  try {
    dispatch({
      type: LOADING,
      payload: { loading_status: true },
    });
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/ctoken/`,
      body,
      config
    );
    try{
      if (res.data.status == 0){
    setAlert(res.data.response)
  }else if (res.data.error_description.includes("Invalid credentials given")){
    let error = "[Error] Incorrect password!"
    setAlert(error)
    dispatch({
      type: ALERT,
      payload: { alert_message: error},
    });
  }else{
    setAlert("Logged in successfully!")
  }}
  catch (e){
    
  }
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    // gtagEvent("Login", "AUTH", "Login User", 1);
    dispatch({
      type: ALERT,
      payload: { alert_message: "You are successfully logged In" },
    });
    dispatch(load_user());
  } catch (err) {
    if (err.response.data.error_description.includes("Invalid credentials given")){
      let error = "[Error] Incorrect password!"
      setAlert(error)
      dispatch({
        type: ALERT,
        payload: { alert_message: error},
      });
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const signup =
  (name, company_name, email, phone, password1, password2, setAlert) =>
    async (dispatch) => {
      const config = {
        headers: {
          "X-CSRFToken": csrftoken,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        name,
        company_name,
        phone,
        email,
        password1,
        password2,
      });

      try {
        dispatch({
          type: LOADING,
          payload: { loading_status: true },
        });
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/auth/register/`,
          body,
          config
        );

        // gtagEvent("Register", "AUTH", "Register User", 1);
        if (res.data.status == 1) {
          localStorage.setItem('access', res.data.response);
          dispatch(load_user());
        } else  {
          let err_res = ""
          for (const field in res.data.response) {
            err_res += res.data.response[field].join('\n') + '\n'; // Append error messages for each field
          }
      
          console.log(err_res)
          setAlert(err_res)
          dispatch({
            type: ALERT,
            payload: { alert_message: err_res },
          });
        }
        return res.data

      } catch (err) {
        setAlert(err)
        dispatch({
          type: ALERT,
          payload: { alert_message: err },
        });
      }
    };

export const verify = (token) => async (dispatch) => {
  const config = {
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
  };

  dispatch({
    type: LOADING,
    payload: { loading_status: true },
  });

  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/auth/activate/?token=${token}`,
    config
  );
  if (res.data.status == 1) {
    dispatch({
      type: ALERT,
      payload: { alert_message: "Account verified successfully, logging in!" },
    });
    // gtagEvent("Verify Account", "AUTH", "Verify Registered User Account", 1);
    localStorage.setItem('access', res.data.response);
    dispatch(load_user());

  } else if (res.data.status == 0) {
    dispatch({
      type: ALERT,
      payload: { alert_message: res.data.response + " Try to login. If fail to login, contact support." },
    });
    return 0
  }
};


export const reset_password = (email, redirect_url) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
    },
  };

  const body = JSON.stringify({ email: email, redirect_url: redirect_url });

  try {
    dispatch({
      type: LOADING,
      payload: { loading_status: true },
    });

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password/`,
      body,
      config
    );
    return res.data
  } catch (err) {
    dispatch({
      type: ALERT,
      payload: { alert_message: "Cannot find email" },
    });
  }
};

export const reset_password_confirm =
  (uidb64, token, password,setSuccess, set_alert) => async (dispatch) => {

    const config = {
      headers: {
        "X-CSRFToken": csrftoken,
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ password, uidb64, token });
    if (uidb64 == null || token == null) {
      dispatch({
        type: ALERT,
        payload: { alert_message: "Token is used of expired, try to login or contact support" },
      });
      set_alert("Token is used of expired")
      return;
    }
    try {
      dispatch({
        type: LOADING,
        payload: { loading_status: true },
      });
      const res = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/password-reset-complete`,
        body,
        config
      );
      console.log(res);
    setSuccess(res.data.response);
      // gtagEvent("Reset Password Completed", "AUTH", "Reset Password Completed", 1);

    } catch (err) {
      console.log(err.response.data.password);

      set_alert(err.response.data.password)
      dispatch({
        type: ALERT,
        payload: { alert_message: "Password is too weak. \n It must include a capital letter, numbers or special signs to make it more secure" },
      });
    }
  };

export const get_user_locale_language = async () => {
  const config = {
    headers: {
      "X-CSRFToken": csrftoken,
      "Authorization": `Bearer ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };


  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/auth/get-user-locale-language/`,
      config
    )
    if (res.data.status == 1) {

      return res.data.response
    }
  } catch (err) {
    return 'en'
  }
};
export const change_user_locale_language = async (language) => {
  const config = {
    headers: {
      "X-CSRFToken": csrftoken,
      "Authorization": `Bearer ${localStorage.getItem("access")}`,
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  };

  const body = JSON.stringify({ language });

  try {

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/change-user-locale-language/`,
      body,
      config
    );
    if (res.data.status == 1) {
      let html = alert_box(res.data.response, 'alertBoxSuccess');
      let AlertManager = document.getElementById("AlertsManager");
      AlertManager.innerHTML += html;
    } else if (res.data.status == 0) {
      let html = alert_box(res.data.response, 'alertBoxError');
      let AlertManager = document.getElementById("AlertsManager");
      AlertManager.innerHTML += html;
    }
  } catch (err) {
    let html = alert_box("Couldn't change the language.", 'alertBoxError');

    let AlertManager = document.getElementById("AlertsManager");
    AlertManager.innerHTML += html;
  }
};



export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  // gtagEvent("User Logged Out", "AUTH", "User Logged Out", 1);
  dispatch({
    type: ALERT,
    payload: { alert_message: "You are logged out from your account" },
  });
};