import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
	Alert,
	Box,
	Button,
	Typography,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
  CircularProgress
} from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat'; // Import Chat Icon
import { axios_post_call } from "../components/AxiosCall";

const FeedbackForm = ({ user }) => {
	const navigate = useNavigate();
	const [loading, set_loading] = useState(false)
	const [alert, set_alert] = useState(false)	
	const [feedback, set_feedback] = useState("");
	const [openDialog, setOpenDialog] = useState(false); // State for controlling the dialog

	const submit_feedback_form = async () => {
		let url = '/system/submit-feedback-form/';
		let res = await axios_post_call(url, { feedback }, set_loading, set_alert);
    set_feedback("")
    	set_alert(res.data.response);
    	set_loading(false);
	}

	const handleOpenDialog = () => {
		setOpenDialog(true);
	}

	const handleCloseDialog = () => {
		setOpenDialog(false);
	}

	return (
		user && <Box>
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogTitle>Hi {user.name}!</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please provide your feedback, suggestion, or comment:
					</DialogContentText>
					<TextField
						label="Feedback"
						variant="outlined"
						multiline
						rows={5}
						value={feedback}
						onChange={(e) => set_feedback(e.target.value)}
						required
						fullWidth
					/>
				</DialogContent>
        {alert && (
				<Alert
					onClose={() => set_alert(false)}
					severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
					sx={{ width:"90%", m: "auto", zIndex: 9923213 }}
          			fullWidth
				>
					{alert}
				</Alert>
			)}
				<DialogActions>
					<Button onClick={handleCloseDialog}>Cancel</Button>
					<Button onClick={submit_feedback_form} variant="contained" >
						{loading ? <CircularProgress /> : "Submit"}
					</Button>
				</DialogActions>
			</Dialog>

			{/* Chatbot Icon */}
      
			<Button
				onClick={handleOpenDialog}
				sx={{ position: 'fixed', bottom: 16, left: 16,mb:"40px", zIndex: 9999, color:"white" ,bgcolor:"primary.main", "&:hover":{bgcolor:"primary.dark"} }}
				startIcon={<ChatIcon />}
			>
				Feedback
			</Button>

		
		</Box>
	);
};

const mapStateToProps = (state) => ({
	state: state,
	user: state.auth.user,
});

export default connect(mapStateToProps, {  })(FeedbackForm);
