import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import { TextField, Button, CircularProgress, Alert, Typography, Link } from "@mui/material";
import { Box } from "@mui/system";

const Login = ({ login, user, set_active_tab }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setAlert(false)
    setLoading(true);
    await login(email, password, setAlert);
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      if (localStorage.getItem("access")) {
        navigate(`/`);
      }
    }
  }, [user]);

  return (
    <Box component="form" onSubmit={onSubmit} sx={{width:1}}>
      <TextField
        id="email"
        placeholder="Email"
        variant="outlined"
        margin="dense"
        size="small"
        type="email"
        name="email"
        value={email}
        onChange={onChange}
        required
        fullWidth
      />
      <TextField
        id="password"
        placeholder="Password"
        variant="outlined"
        margin="dense"
        size="small"
        type="password"
        name="password"
        value={password}
        onChange={onChange}
        minLength="1"
        required
        fullWidth
      />

      {alert && (
        <Alert
          onClose={() => setAlert(false)}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
          sx={{ m: "auto", my: 2, zIndex: 9923213 }}
        >
          {alert}
        </Alert>
      )}
<Box sx={{my:1}}></Box>
      <Button fullWidth variant="contained" type="submit">
        {loading ? <CircularProgress color="secondary" /> : "Login"}
      </Button>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Link component={RouterLink} to="/reset-password" variant="subtitle2" color="text.secondary">
          Forgot your password?
        </Link>
        <Typography variant="subtitle2" color="text.secondary">
          Don't have an account? <Link sx={{cursor:"pointer"}} onClick={()=>{set_active_tab("register")}}>Sign Up</Link>
        </Typography>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
  