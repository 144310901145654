import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      extraLight: "rgb(0, 71, 171, 0.1)",
      light: "rgb(0, 71, 171, 0.8)",
      main: "#0047ab", // Dark blue
      contrastText:"black"
    },
    secondary: {
      main: "#C55605", // Pink (can be used for highlighting or alerts)
      contrastText:"black"
    },
    background: {
      default: "#ffffff", // White background
      paper: "#f0f0f0", // Light grey paper color
    },
    text: {
      primary: "#333333", // Dark grey (for main text)
      secondary: "#878a99", // Medium grey (for secondary text)
    },
    error: {
      main: "#d32f2f", // Dark red (for error messages)
    },
    success: {
      main: "#388e3c", // Dark green (for success messages)
    },
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    link: {
      cursor: "pointer",
      color: "#0047ab", // Dark blue (for links)
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "0.01em",
    },
    button: {
      textTransform: "capitalize",
      fontSize: "1rem",
      fontWeight: 600,
      color:"white"
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth:'100px',
          borderRadius: "8px",
          boxShadow: "none",
          padding: "12px 24px",
          height:'53px',
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient background
            color: "#ffffff", // White text
            "&:hover": {
              background: "linear-gradient(45deg, #0D47A1 30%, #0D47A1 90%)", // Darker gradient on hover
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)", // Gradient background
            color: "#ffffff", // White text
            "&:hover": {
              background: "linear-gradient(45deg, #FF80AB 30%, #FF80AB 90%)", // Darker gradient on hover
            },
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 15,
            boxShadow:5,
          background: "linear-gradient(45deg, #f0f0f0 30%, #ffffff 90%)", // Gradient paper background
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
