import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { lighten } from '@mui/material/styles';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import { logout } from "../actions/auth";

// Icons 
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LogoutIcon from '@mui/icons-material/Logout';
import UsersIcon from '@mui/icons-material/Person';
import OverviewIcon from '@mui/icons-material/Assessment';


import { useTheme } from '@emotion/react';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const AppSidebar = ({ children, logout, state, user }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const return_icon = (text) => {
    switch (text) {
      case "Overview":
        return <OverviewIcon sx={{ color: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "white" : "#8c8c8c", }} />
        break;
      case "Users":
        return <UsersIcon sx={{ color: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "white" : "#8c8c8c", }} />
        break;

      case "Logout":
        return <LogoutIcon sx={{ color: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "white" : "#8c8c8c", }} />
        break;


      default:
        return <LogoutIcon sx={{ color: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "white" : "#8c8c8c", }} />
        break;
    }
  };


  const logout_user = () => {
    logout();
  };


  const handleDrawerOpen = () => {
    setOpen(true);
    window.localStorage.setItem("sidebar_opened", true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    window.localStorage.setItem("sidebar_opened", false);
  };

  return (
    <Box sx={{ display: 'flex', bgcolor: "#F3F3F9" }}>
      <Drawer variant="permanent" open={open} >
        <DrawerHeader sx={{
          display: "flex",
          textAlign: "left",
          alignItems: "left",
          justifyContent: "left",
          float: "left",
          ml: 1.8,
        }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              borderRadius: 15,

              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton onClick={handleDrawerClose} color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{
              borderRadius: 15,
              ...(!open && { display: 'none' }),
            }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          <Typography variant='h3' sx={{ ml: 1, opacity: open ? 1 : 0 }}>A2P Generator</Typography>

        </DrawerHeader>
        <List>
          {['Overview', 'Users', 'Divider', 'Logout'].map((text, index) => (
            text == "Divider" ?
              <ListItem key={text} disablePadding sx={{ display: 'block', }}>
                <Divider />
              </ListItem>
              :
              <ListItem key={text} disablePadding sx={{ display: 'block', }}>
                <Tooltip title={!open ? text : ""} placement='right'>
                  {user && (text == "Admin" && !user.is_superuser) ? "" :
                    user && user.agency_or_individual == 0 && text == 'Payouts' ? "" :
                      <ListItemButton
                        size="small"
                        onClick={() => {
                          if (text == "Admin") {
                            window.open(process.env.REACT_APP_BACKEND_URL.split("/api")[0] + "/admin/", "_blank")
                          } else if (text == "Logout") {
                            logout_user()
                            navigate(`/`);
                          } else {
                            navigate("/admin-panel/" + text.toLowerCase().replaceAll(" ", "-"))
                          }
                        }}
                        sx={{
                          height: 40,
                          justifyContent: open ? 'initial' : 'center',
                          mx: 1,
                          borderRadius: 1,
                          bgcolor: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "primary.main" : "primary.extraLight",
                          "&:hover":{
                            bgcolor: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "primary.light" : "",

                          }
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : 'auto',
                            justifyContent: 'center',
                            fontSize: "10px !important",
                          }}
                        >
                          {return_icon(text)}
                        </ListItemIcon>
                        <ListItemText sx={{ opacity: open ? 1 : 0 }}
                          children={
                            <Typography variant='subtitle2' sx={{
                              color: location.pathname.includes(text.toLowerCase().replaceAll(" ", "-")) ? "white" : "black",
                            }}>{text}</Typography>
                          }
                        />
                      </ListItemButton>}
                </Tooltip>
              </ListItem>
          ))}
          <Box sx={{ mx: 1, px: 1 }}>
            {open &&
              <Typography sx={{ color: "lightgrey" }} variant='subtitle2'>{user && user.email}</Typography>
            }
          </Box>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, px: 3, minHeight: "100vh" }}>
        {children}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(AppSidebar);
