import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  Paper,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import { axios_get_call, axios_post_call } from "../../components/AxiosCall";
import { connect } from "react-redux";
import RenderFormSubmissionsResponse from "./RenderFormSubmissionsResponse";
import { Link, useNavigate } from "react-router-dom";

const SaaSForm = ({ user }) => {
  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [form_submissions_response, set_form_submissions_response] =
    useState(false);
  const [empty_form, set_empty_form] = useState(0);

  const [form_data, set_form_data] = useState({
    customer_name: "",
    business_name: "",
    a2p_phone: "",
    consent: "",
    consent_input_value: "",
    url: "",
    appt_url: "",
    selling: [],
    biz_type: "",
    use_cases: [],
  });

  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    set_form_data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    // If "all" checkbox is selected, select all other checkboxes
    if (name === "all" && checked) {
      set_form_data((prevData) => ({
        ...prevData,
        use_cases: ["1", "2", "3", "4", "5", "6"],
      }));
    } else if (name === "all" && form_data.use_cases.length === 6) {
      set_form_data((prevData) => ({
        ...prevData,
        use_cases: [],
      }));
    } else {
      // Otherwise, update the selected checkboxes
      let updatedUseCases;
      if (checked) {
        updatedUseCases = [...form_data.use_cases, name];
      } else {
        updatedUseCases = form_data.use_cases.filter((item) => item !== name);
      }
      set_form_data((prevData) => ({
        ...prevData,
        use_cases: updatedUseCases,
      }));
    }
  };

  const handleRadioChange = (e) => {
    // Update form data for radio button selection
    set_form_data((prevData) => ({
      ...prevData,
      biz_type: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "/saas/submit-form/";
    let res = await axios_post_call(url, form_data, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_form_submissions_response(res.data.response);
      set_alert("Form submitted successfully");
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  useEffect(() => {
    // Reset form after submission
    set_form_data({
      customer_name: "",
      business_name: "",
      a2p_phone: "",
      consent: "",
      consent_input_value: "",
      url: "",
      appt_url: "",
      selling: [],
      biz_type: "",
      use_cases: [],
    });
  }, [empty_form]);

  return (
    <Tooltip title={user ? "" : "Please login to access the form"} followCursor>
      <Box
        sx={{
          opacity: !user && 0.3,
          cursor: !user && "not-allowed !important",
        }}
      >
        {form_submissions_response && (
          <RenderFormSubmissionsResponse
          set_alert={set_alert}
            empty_form={empty_form}
            set_empty_form={set_empty_form}
            form_submissions_response={form_submissions_response}
            set_form_submissions_response={set_form_submissions_response}
          />
        )}
        <Typography
          align="center"
          mb={2}
          variant="subtitle2"
          text="text.secondary"
        >
          {user ? "" : "Please login to access the form"}
        </Typography>
        <Typography align="center" mb={2} variant="h2">
          Generate A2P Compliance Use Cases
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", flexDirection:{xs:"column", sm:"column", md:"row"} }}>
            <TextField
              disabled={!user}
              label="Customer Name"
              variant="outlined"
              name="customer_name"
              value={form_data.customer_name}
              onChange={(e) => {
                if (user) handleChange(e);
              }}
              required
              fullWidth
              sx={{
                mb: 2,
                mr: {xs:0, sm:0, md:0.5},
              }}
            />

            <TextField
              disabled={!user}
              label="Business Name"
              variant="outlined"
              name="business_name"
              value={form_data.business_name}
              onChange={(e) => {
                if (user) handleChange(e);
              }}
              required
              fullWidth
              sx={{
                mb: 2,
                ml: {xs:0, sm:0, md:0.5},
              }}
            />
          </Box>
          <TextField
            disabled={!user}
            label="A2P Phone Number"
            variant="outlined"
            name="a2p_phone"
            value={form_data.a2p_phone}
            onChange={(e) => {
              if (user) handleChange(e);
            }}
            required
            fullWidth
            sx={{
              mb: 2,
            }}
          />

          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend">
              Do you have a website URL to capture user consent?
            </FormLabel>
            <RadioGroup
              aria-label="consent"
              name="consent"
              value={form_data.consent}
              onChange={(e) => {
                if (user) handleChange(e);
              }}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          {/* Conditional rendering based on consent */}
          {form_data.consent === "yes" ? (
            <TextField
              disabled={!user}
              label="Optin Form URL"
              variant="outlined"
              name="consent_input_value"
              value={form_data.consent_input_value}
              onChange={(e) => {
                if (user) handleChange(e);
              }}
              fullWidth
              Required
              sx={{
                mb: 2,
              }}
            />
          ) : (
            form_data.consent === "no" && (
              <TextField
                disabled={!user}
                label="Explain how you get their consent"
                variant="outlined"
                name="consent_input_value"
                value={form_data.consent_input_value}
                multiline
                rows={2}
                onChange={(e) => {
                  if (user) handleChange(e);
                }}
                fullWidth
                Required
                sx={{
                  mb: 2,
                }}
              />
            )
          )}
          <Box sx={{ display: "flex", flexDirection:{xs:"column", sm:"column", md:"row" } }}>
            <TextField
              disabled={!user}
              label="Company Website URL"
              variant="outlined"
              name="url"
              value={form_data.url}
              onChange={(e) => {
                if (user) handleChange(e);
              }}
              fullWidth
              sx={{
                mb: 2,
                mr: {xs:0, sm:0, md:0.5},
              }}
            />

            <TextField
              disabled={!user}
              label="Appointment booking page url (optional)"
              variant="outlined"
              name="appt_url"
              value={form_data.appt_url}
              onChange={(e) => {
                if (user) handleChange(e);
              }}
              fullWidth
              sx={{
                mb: 2,
                ml: {xs:0, sm:0, md:0.5},
              }}
            />
          </Box>
          <FormLabel component="legend">
            Are you Selling product or Service?
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={form_data.selling.includes("product")}
                onChange={(e) => {
                  if (user) {
                    const { value, checked } = e.target;
                    set_form_data((prevData) => ({
                      ...prevData,
                      selling: checked
                        ? [...prevData.selling, value]
                        : prevData.selling.filter((item) => item !== value),
                    }));
                  }
                }}
                name="selling"
                value="product"
              />
            }
            label="Product"
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={form_data.selling.includes("service")}
                onChange={(e) => {
                  if (user) {
                    const { value, checked } = e.target;
                    set_form_data((prevData) => ({
                      ...prevData,
                      selling: checked
                        ? [...prevData.selling, value]
                        : prevData.selling.filter((item) => item !== value),
                    }));
                  }
                }}
                name="selling"
                value="service"
              />
            }
            label="Service"
            sx={{ mb: 2 }}
          />
          <FormLabel component="legend">Your company uses:</FormLabel>

          <RadioGroup
            aria-label="biz_type"
            name="biz_type"
            value={form_data.biz_type}
            onChange={(e) => {
              if (user) handleRadioChange(e);
            }}
            sx={{
              mb: 2,
            }}
          >
            <FormControlLabel
              value="business website"
              control={<Radio />}
              label="Business Website"
            />
            <FormControlLabel
              value="ecommerce store"
              control={<Radio />}
              label="Ecommerce Store"
            />
          </RadioGroup>
          <FormGroup>
            <FormLabel component="legend">What are your Use Cases:</FormLabel>
            {[1, 2, 3, 4, 5, 6].map((number) => (
              <FormControlLabel
                key={number}
                control={
                  <Checkbox
                    checked={form_data.use_cases.includes(`${number}`)}
                    onChange={(e) => {
                      if (user) handleCheckboxChange(e);
                    }}
                    name={`${number}`}
                  />
                }
                label={capitalizeFirstLetter(
                  number === 1
                    ? "product or service offers"
                    : number === 2
                    ? "marketing and promotions"
                    : number === 3
                    ? "appointment and other type of reminders"
                    : number === 4
                    ? "account notification"
                    : number === 5
                    ? "request updates"
                    : "transaction notifications"
                )}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={form_data.use_cases.length === 6}
                  onChange={(e) => {
                    if (user) handleCheckboxChange(e);
                  }}
                  name="all"
                />
              }
              label="All"
            />
          </FormGroup>
          <br />
          {/* Submit button */}
          <Button
            disabled={!user}
            sx={{ fontSize: "1.25rem" }}
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
          >
            {loading ? <CircularProgress size={24} /> : "GENERATE"}
          </Button>
        </form>
        {alert && (
          <Alert
            onClose={() => {
              set_alert("");
            }}
            severity={
              alert.toLowerCase().includes("error") ||
              alert.toLowerCase().includes("everyone.")
                ? "error"
                : "success"
            }
            sx={{
              mt: 2,
            }}
          >
            {alert}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {alert ==
            "The first submission is free for everyone. If you're an agency or freelancer and need to use this for all your clients, please check our plans by clicking below" && (
            <Link sx={{ cursor: "pointer" }} to="/subscription">
              <Typography variant="h3" my={2} align="center">
                CHECK OUR PLANS
              </Typography>
            </Link>
          )}
        </Box>
       
      </Box>
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(SaaSForm);
