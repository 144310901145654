import React, { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { signup } from "../../actions/auth";
import {
  TextField,
  Button,
  CircularProgress,
  Alert,
  Typography,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import Activate from "./Activate"; // Import the Activate component
import ConfettiExplosion from "react-confetti-explosion";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Signup = ({ signup, user, set_active_tab }) => {
  const [phone, set_phone] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company_name: "",
    email: "",
    password1: "",
    password2: "",
  });

  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showActivate, setShowActivate] = useState(false); // State to control showing/hiding Activate component
  const [show_Confetti, set_show_Confetti] = useState(false); // State to control showing/hiding Activate component
  const navigate = useNavigate();

  const { name, company_name, email, password1, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    setAlert(false);
    e.preventDefault();
    if (password1 === password2) {
      setLoading(true);
      const res = await signup(
        name,
        company_name,
        email,
        phone,
        password1,
        password2,
        setAlert
      );
      setLoading(false);
      if (res && res.status === 1) {
        set_show_Confetti(true);
        window.gtag_event('event', 'conversion_event_signup', {
          // <event_parameters>
        });
        // Hide confetti after 3 seconds
        setTimeout(() => {
          set_show_Confetti(false);
        }, 3000); // 3 seconds
        setTimeout(() => {
          setShowActivate(true); // Show the Activate component
        }, 1500); // 3 seconds
      }
    } else {
      setAlert("Passwords do not match");
    }
  };

  useEffect(() => {
    if (user) {
      if (localStorage.getItem("access")) {
        navigate("/");
      }
    }
  }, [user]);

  return showActivate ? (
    <Activate />
  ) : (
    <Box>
      {show_Confetti && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            width: "50%",
            height: "25%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {show_Confetti && <ConfettiExplosion />}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
          <style
        dangerouslySetInnerHTML={{
          __html: `.PhoneInputCountry {
            display:none
        }`,
        }}
      />
        <Box component="form" onSubmit={onSubmit} sx={{ width: "100%" }}>
          {successMessage && (
            <Alert severity="success" onClose={() => setAlert(false)}>
              {successMessage}
            </Alert>
          )}
          {!successMessage && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                }}
              >
                <TextField
                  id="name"
                  placeholder="Name"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  type="text"
                  name="name"
                  value={name}
                  onChange={onChange}
                  required
                  fullWidth
                  sx={{
                    mr: { xs: 0, sm: 0, md: 1 },
                  }}
                />
                <TextField
                  id="name"
                  placeholder="Company Name"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  type="text"
                  name="company_name"
                  value={company_name}
                  onChange={onChange}
                  required
                  fullWidth
                  sx={{
                    ml: { xs: 0, sm: 0, md: 1 },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                }}
              >
                <Box sx={{ flex: 1, width: 1,}}>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    country="US"
                    defaultCountry="US"
                    flags={false}
                    flag_url={false}
                    onChange={set_phone}
                    inputComponent={PhoneInputTextField}
                    defaultCountry="US" // You can set the default country here
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    id="email"
                    placeholder="Email"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    required
                    fullWidth
                    sx={{
                      ml: { xs: 0, sm: 0, md: 1 },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                }}
              >
                <TextField
                  id="password1"
                  placeholder="Password"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  type="password"
                  name="password1"
                  value={password1}
                  onChange={onChange}
                  minLength={6}
                  required
                  fullWidth
                  sx={{
                    mr: { xs: 0, sm: 0, md: 1 },
                  }}
                />
                <TextField
                  id="password2"
                  placeholder="Confirm Password"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={onChange}
                  minLength={6}
                  required
                  fullWidth
                  sx={{
                    ml: { xs: 0, sm: 0, md: 1 },
                  }}
                />
              </Box>

              {alert ? (
                <Alert severity="error" onClose={() => setAlert(false)}>
                  <Typography style={{ whiteSpace: "pre-line" }}>
                    {alert}
                  </Typography>
                </Alert>
              ) : (
                <Box mb={2}>
                  <Typography variant="subtitle2" color="error" m={0} p={0}>
                    Password Requirements:
                  </Typography>
                  <ul style={{ margin: 0, padding: 0 }}>
                    <Typography
                      sx={{ fontSize: { xs: 10, sm: 12, md: "initial" } }}
                      variant="subtitle2"
                      color="text.secondary"
                    >
                      <Box sx={{ display: "inline" }}>
                        {/* Original condition for password length */}
                        {password1.length >= 8 ? "✅" : "❌"}
                      </Box>{" "}
                      Minimum 8 characters
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: 10, sm: 12, md: "initial" } }}
                      variant="subtitle2"
                      color="text.secondary"
                    >
                      <Box sx={{ display: "inline" }}>
                        {/* Check if all characters are lowercase alphabets */}
                        {/^[a-z]+$/.test(password1)
                          ? "❌"
                          : password1.length >= 1
                          ? "✅"
                          : "❌"}
                      </Box>{" "}
                      One number or uppercase character
                    </Typography>

                    <Typography
                      sx={{ fontSize: { xs: 10, sm: 12, md: "initial" } }}
                      variant="subtitle2"
                      color="text.secondary"
                    >
                      <Box sx={{ display: "inline" }}>
                        {/* Check for entirely numeric password */}
                        {/^\d+$/.test(password1)
                          ? "❌"
                          : password1.length >= 1
                          ? "✅"
                          : "❌"}
                      </Box>{" "}
                      Password should not be entirely numeric
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: 10, sm: 12, md: "initial" } }}
                      variant="subtitle2"
                      color="text.secondary"
                    >
                      <Box sx={{ display: "inline" }}>
                        {/* Check for entirely numeric password */}
                        {password1.length > 0 && password2.length > 0 && password1 == password2 ? "✅" : "❌"}
                      </Box>{" "}
                      Passwords must match
                    </Typography>
                  </ul>
                </Box>
              )}
              <Box sx={{ my: 1 }}></Box>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Register"}
              </Button>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Already have an account?{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      set_active_tab("login");
                    }}
                  >
                    Login
                  </Link>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box></Box>
    </Box>
  );
};

const PhoneInputTextField = React.forwardRef(
  ({ value, onChange, ...props }, ref) => (
    <TextField
      {...props}
      inputRef={ref}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      type="tel"
      size="small"
      fullWidth
      inputProps={{ maxLength: 16 }}
      sx={{
        mr: { xs: 0, sm: 0, md: 1 },
      }}
    />
  )
);

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { signup })(Signup);
